import React from 'react';

interface AbcBorderProps {
  selected: boolean;
}

export const AbcBorder = ({ selected }: AbcBorderProps) => {
  const fill = selected ? '#E7F0FF' : '#116DFF';

  return (
    <svg
      width="50"
      height="24"
      viewBox="0 0 50 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.856 17.9999H20.316L19.392 15.4799H15.136L14.212 17.9999H12.742L16.508 7.98992H18.076L21.856 17.9999ZM15.584 14.2339H18.944L17.264 9.64192L15.584 14.2339Z"
        fill={fill}
      />
      <path
        d="M24.8201 17.9999H23.4341V7.91992H24.8341V12.1759C25.0675 11.7559 25.3895 11.4246 25.8001 11.1819C26.2201 10.9393 26.6961 10.8179 27.2281 10.8179C27.8535 10.8179 28.4088 10.9766 28.8941 11.2939C29.3888 11.6113 29.7761 12.0499 30.0561 12.6099C30.3455 13.1699 30.4901 13.8093 30.4901 14.5279C30.4901 15.2466 30.3408 15.8859 30.0421 16.4459C29.7528 17.0059 29.3561 17.4446 28.8521 17.7619C28.3575 18.0699 27.7975 18.2239 27.1721 18.2239C26.6495 18.2239 26.1828 18.1119 25.7721 17.8879C25.3708 17.6639 25.0535 17.3653 24.8201 16.9919V17.9999ZM24.8341 14.6539V15.5499C24.9835 15.9886 25.2401 16.3479 25.6041 16.6279C25.9775 16.8986 26.4021 17.0339 26.8781 17.0339C27.3075 17.0339 27.6808 16.9266 27.9981 16.7119C28.3248 16.4973 28.5768 16.2033 28.7541 15.8299C28.9408 15.4473 29.0341 15.0133 29.0341 14.5279C29.0341 13.7906 28.8428 13.1886 28.4601 12.7219C28.0868 12.2553 27.5921 12.0219 26.9761 12.0219C26.5655 12.0219 26.1968 12.1339 25.8701 12.3579C25.5435 12.5726 25.2868 12.8806 25.1001 13.2819C24.9228 13.6739 24.8341 14.1313 24.8341 14.6539Z"
        fill={fill}
      />
      <path
        d="M37.3615 15.9699L38.2715 16.8659C37.9355 17.2953 37.5249 17.6313 37.0395 17.8739C36.5542 18.1073 36.0222 18.2239 35.4435 18.2239C34.7529 18.2239 34.1369 18.0653 33.5955 17.7479C33.0635 17.4306 32.6435 16.9966 32.3355 16.4459C32.0369 15.8859 31.8875 15.2466 31.8875 14.5279C31.8875 13.7999 32.0369 13.1606 32.3355 12.6099C32.6435 12.0499 33.0682 11.6113 33.6095 11.2939C34.1509 10.9766 34.7669 10.8179 35.4575 10.8179C36.6522 10.8179 37.5949 11.2706 38.2855 12.1759L37.3475 13.0719C36.9275 12.3719 36.3069 12.0219 35.4855 12.0219C34.8602 12.0219 34.3469 12.2506 33.9455 12.7079C33.5535 13.1559 33.3575 13.7626 33.3575 14.5279C33.3575 15.2746 33.5582 15.8813 33.9595 16.3479C34.3609 16.8053 34.8789 17.0339 35.5135 17.0339C35.9055 17.0339 36.2555 16.9453 36.5635 16.7679C36.8715 16.5813 37.1375 16.3153 37.3615 15.9699Z"
        fill={fill}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M49 1H1.00002L1.00001 23H49V1ZM1.00002 0C0.447731 0 1.52588e-05 0.447716 1.52588e-05 1V23C1.52588e-05 23.5523 0.44773 24 1.00001 24H49C49.5523 24 50 23.5523 50 23V1C50 0.447715 49.5523 0 49 0H1.00002Z"
        fill={fill}
      />
    </svg>
  );
};
