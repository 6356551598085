import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import {
  useEnvironment,
  useTranslation,
  WidgetProps,
} from '@wix/yoshi-flow-editor';
import {
  FormError,
  FormValues,
  FormViewer,
  FormViewerContext,
} from '@wix/form-viewer/widget';
import { FormHandle } from '@wix/form-viewer';
import { EmptyState } from './components/EmptyState';
import { classes, vars } from './index.st.css';
import { useStyles } from '@wix/tpa-settings/react';
import classNames from 'classnames';
import stylesParams from '../stylesParams';
import { ControllerProps } from './interfaces';
import { getShadowOffsets } from '../utils/shadow-offsets';
import { ISettingsColor, StyleParamType } from '@wix/tpa-settings';

const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  const translation = useTranslation();
  const { i18n } = useMemo(() => translation, []);
  const [values, setValues] = useState<FormValues>({});
  const [errors, setErrors] = useState<FormError[]>([]);
  const formViewer = useRef<FormHandle>(null);
  const styles = useStyles();
  const { isEditor } = useEnvironment();

  const shadowOffsets = getShadowOffsets({
    angle: styles.get(stylesParams.shadowAngle),
    distance: styles.get(stylesParams.shadowDistance),
  });

  const onValidate = useCallback((_errors: FormError[]) => {
    setErrors(_errors);
  }, []);

  // This function is needed for form-viewer to get the styles from the form-app
  const getStylesForHost = () =>
    Object.keys(stylesParams).reduce(
      (acc, key) => {
        const styleParam = stylesParams[key];
        const value = styles.get(styleParam);

        if (styleParam.type === StyleParamType.Boolean) {
          acc.styleParams.booleans[key] = value;
        } else if (styleParam.type === StyleParamType.Color) {
          acc.styleParams.colors[key] = {
            themeName: (value as ISettingsColor).name,
            value: (value as ISettingsColor).value,
          };
        } else if (styleParam.type === StyleParamType.Font) {
          acc.styleParams.fonts[key] = value;
        } else if (styleParam.type === StyleParamType.Number) {
          acc.styleParams.numbers[key] = value;
        }

        return acc;
      },
      {
        ...(props.host?.style ?? {}),
        styleParams: {
          ...(props.host?.style?.styleParams ?? {}),
          booleans: { ...(props.host?.style?.styleParams?.booleans ?? {}) },
          colors: { ...(props.host?.style?.styleParams?.colors ?? {}) },
          fonts: { ...(props.host?.style?.styleParams?.fonts ?? {}) },
          numbers: { ...(props.host?.style?.styleParams?.numbers ?? {}) },
        },
        compId: props.host.id,
      },
    );

  let style = useMemo(() => getStylesForHost(), []);

  if (isEditor) {
    style = getStylesForHost();
  }

  if (!props.formId || props.loading || props.formDeleted) {
    return <EmptyState loading={props.loading} />;
  }

  return (
    <FormViewerContext {...props}>
      <div
        style={{
          [vars.shadowXOffset]: shadowOffsets.xOffset + 'px',
          [vars.shadowYOffset]: shadowOffsets.yOffset + 'px',
          overflow: isEditor ? 'hidden' : 'visible',
        }}
        className={classNames(classes.container, {
          [classes.shadow]: styles.get(stylesParams.enableShadow),
        })}
        data-hook="Form-wrapper"
      >
        <FormViewer
          i18n={i18n}
          ref={formViewer}
          formId={props.formId}
          values={values}
          errors={errors}
          onValidate={onValidate}
          onChange={setValues}
          forcedState={[props.forceView].filter(Boolean)}
          style={style}
        />
      </div>
    </FormViewerContext>
  );
};

export default Widget;
